export default {
  websiteBaseUrl: 'http://localhost:8000',
  apiBaseUrl: 'http://localhost:8000/api',
  dashboardBaseUrl: 'http://localhost:8000/dashboard',
  useProxyForApiCall: false,
  auth: {
    loginUrl: 'http://localhost:8000/login',
    loginApiUrl: '/user/login',
    userAuthenticationUrl: '/user/get',
  },
  googleCaptcha: {
    siteKey: '6LeuuJcUAAAAAJtpfdUpKvjxk62bbz3KCNVfGkuz',
  },
  facebook: {
    appId: '1199755590502559',
  },
  sentry: {
    DSN: '',
    environment: 'develop',
  },
  intercom: {
    IntercomAppID: 'XXXXXXXXX',
  },
  clarity: {
    projectId: 'XXXXXXXXXX',
  },
  intrack: {
    IntrackAppKey: 'XXXXXXXXX',
    IntrackAuthKey: 'XXXXXXXXX',
    IntrackPublicKey: 'XXXXXXXXX',
  },
  webEngage: {
    licenseCode: '82617805',
  },
};
