import defaultConfig from './default';
import developmentConfig from './development';
import productionConfig from './production';
import stagingConfig from './staging';
import testConfig from './test-env';
import kamranConfig from './kamran';
import mohsenConfig from './mohsen';
import devConfig from './dev';
import pedramConfig from './pedram';
import mrkh13DevConfig from './mrkh13.dev';
import cypressConfig from './cypress';
import behroozConfig from './behrooz';

const env =
  process.env.NEXT_PUBLIC_ENV || process.env.NODE_ENV || 'development';

export const configs = {
  production: productionConfig,
  development: developmentConfig,
  staging: stagingConfig,
  test: testConfig,
  kamran: kamranConfig,
  mohsen: mohsenConfig,
  dev: devConfig,
  pedram: pedramConfig,
  mrkh13Dev: mrkh13DevConfig,
  cypress: cypressConfig,
  behrooz: behroozConfig,
};

const currentConfig = env in configs ? configs[env] : defaultConfig;

export default currentConfig;
